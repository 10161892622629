<template>
  <div class="game_wrap">
    <div class="container">
      <div class="game_Con">
        <div class="gameTitle">METARACE®</div>
        <div class="walletBox">
          <div class="tips">
            Copy the link to the mobile browser or wallet to enter the game
          </div>
          <div class="walletInp">
            <input type="text" class="inp" placeholder="STAY TUNED" />
            <div class="copy">COPY</div>
          </div>
        </div>
        <div class="typeCon">
          <li>
            <img v-lazy="require('@/assets/images/apple.png')" alt="" srcset="" />
            <div class="download">Download on the</div>
            <div class="apps">APP STORE</div>
            <div class="stay">STAY TUNED</div>
          </li>
          <li>
            <img v-lazy="require('@/assets/images/android.png')" alt="" srcset="" />
            <div class="download">Download for</div>
            <div class="apps">ANDROID</div>
            <div class="stay">STAY TUNED</div>
          </li>
          <li>
            <img v-lazy="require('@/assets/images/window.png')" alt="" srcset="" />
            <div class="download">Download for</div>
            <div class="apps">WINDOWS</div>
            <div class="stay">STAY TUNED</div>
          </li>
        </div>
      </div>
      <img v-lazy="require('@/assets/images/gameHouse.png')" alt="" class="gameHouse" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.game_wrap {
  width: 100%;
  height: 676px;
  .container {
    width: 1200px;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    .gameHouse {
      width: 528px;
      height: 642px;
      margin-top: 10px;
    }
    .game_Con {
      display: flex;
      flex-direction: column;
      margin-top: 89px;
      .gameTitle {
        font-size: 75px;
        font-family: "Rubik-BoldItalic";
        margin-bottom: 39px;
      }
      .walletBox {
        margin-bottom: 32px;
        .tips {
          font-size: 19px;
          font-family: "Rubik-Italic";
        }
        .walletInp {
          display: flex;
          margin-top: 9px;
          .inp {
            width: 598px;
            height: 85px;
            outline: none;
            border: 3px solid #c822ff;
            background: transparent;
            color: #fff;
            text-indent: 18px;
            font-size: 36px;
            font-family: "Rubik-Italic";
          }
          .inp:focus{
            outline: none;
            border: 3px solid #c822ff;
          }
          .inp::-webkit-input-placeholder{
            color: #fff;
            font-size: 30px;
          }
          .copy {
            width: 174px;
            height: 85px;
            background: #c822ff;
            border: 3px solid #c822ff;
            text-align: center;
            line-height: 85px;
            color: #040404;
            font-size: 32px;
            font-family: "Rubik-BoldItalic";
          }
        }
      }
      .typeCon {
        display: flex;
        li {
          display: flex;
          flex-direction: column;
          margin-right: 60px;
          img {
            width: 49px;
            height: 58px;
            margin-bottom: 14px;
          }
          .apps {
            margin-top: 9px;
            margin-bottom: 20px;
            font-family: "Rubik-BoldItalic";
            font-size: 32px;
          }
          .stay {
            color: #c822ff;
          }
        }
      }
    }
  }
}
</style>
